import Axios, { type AxiosError, type AxiosRequestConfig } from 'axios'
import { env } from 'env.client'

const AxiosInstance = Axios.create({ baseURL: env.NEXT_PUBLIC_API_URL })

export const customAxiosInstance = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const source = Axios.CancelToken.source()
  const promise = AxiosInstance<T>({
    ...config,
    ...options,
    cancelToken: source.token,
    headers: {
      'Strict-transport-security': 'max-age=63072000; includeSubDomains; preload',
      'Content-Security-Policy': 'default-src "self"',
      'X-Frame-Options': 'deny',
    },
    paramsSerializer: {
      indexes: null,
    },
  }).then(({ data }) => data)

  await Object.defineProperty(promise, 'cancel', {
    value: () => {
      source.cancel('Query was cancelled')
    },
  })

  return promise
}

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>
