import React from 'react'
import { env } from 'env.client'

type FeatureFlagsContextProps = {
  isDepositLimitEnabled: boolean
}

const FeatureFlagsContext = React.createContext<FeatureFlagsContextProps>({
  isDepositLimitEnabled: true,
})

const useFeatureFlagsContext = () => React.useContext(FeatureFlagsContext)

type FeatureFlagsProviderProps = {
  children: React.ReactNode
}

const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderProps) => {
  const contextValues = React.useMemo(
    () => ({
      isDepositLimitEnabled: env.NEXT_PUBLIC_NETWORK !== 'testnet',
    }),
    [],
  )

  return (
    <FeatureFlagsContext.Provider value={contextValues}>{children}</FeatureFlagsContext.Provider>
  )
}

export { FeatureFlagsProvider, useFeatureFlagsContext }
