import { readContract } from '@wagmi/core'
import { keyBy } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { erc20Abi } from 'viem'
import type { Address } from 'viem'
import { useAccount, useBalance } from 'wagmi'
import { useErc20TokensQuery } from 'apollo/generated/graphqlClient'
import type { Erc20Token } from 'apollo/generated/graphqlClient'
import { DEFAULT_VALUE } from 'constants/common'
import { CHAINS, ETH } from 'constants/network'
import { wagmiConfig } from 'lib/wagmi'
import type { EthAsset } from 'types/network'
import { getFormattedNumber, weiToEth } from 'utils/common'

export type Asset = Erc20Token | EthAsset
export type AssetWithBalance = { value: string } & Asset

export const useAssets = () => {
  const { address, chain: activeChain } = useAccount()
  const [assetsBalances, setAssetsBalances] = useState<Record<string, AssetWithBalance>>({})
  const [areErc20TokensLoading, setAreErc20TokensLoading] = useState<boolean>(true)

  const {
    data: ethBalanceData,
    isLoading: isEthBalanceLoading,
    refetch: refetchEthBalance,
  } = useBalance({
    address,
  })
  const { data: { erc20Tokens = [] } = {} } = useErc20TokensQuery()

  const isL1NetworkSelected = activeChain?.id === CHAINS.l1.id

  const handleERC20TokensBalance = useCallback(async () => {
    setAreErc20TokensLoading(true)

    const erc20TokensWithBalances = await Promise.all(
      erc20Tokens.map(async (token: Erc20Token) => {
        const contractAddress = isL1NetworkSelected
          ? token.contract_address_l1
          : token.contract_address_l2

        const balance = await readContract(wagmiConfig, {
          abi: erc20Abi,
          address: contractAddress as Address,
          functionName: 'balanceOf',
          args: [address!],
        })

        const decimals = isL1NetworkSelected ? token.decimals_l1 : token.decimals_l2

        return {
          ...token,
          value: getFormattedNumber(weiToEth(balance.toString(), decimals)),
        }
      }),
    )

    setAssetsBalances((prev) => ({
      ...prev,
      ...keyBy(erc20TokensWithBalances, 'symbol'),
    }))

    setAreErc20TokensLoading(false)
  }, [address, isL1NetworkSelected, erc20Tokens])

  useEffect(() => {
    if (!isEthBalanceLoading) {
      setAssetsBalances((prev) => ({
        ...prev,
        [ETH.symbol]: {
          ...ETH,
          value: ethBalanceData?.formatted ?? DEFAULT_VALUE,
        },
      }))
    }
  }, [ethBalanceData, isEthBalanceLoading])

  useEffect(() => {
    if (erc20Tokens && erc20Tokens.length > 0 && address) {
      void handleERC20TokensBalance()
    } else {
      setAreErc20TokensLoading(false)
    }
  }, [handleERC20TokensBalance, erc20Tokens, address])

  return {
    assets: [ETH, ...erc20Tokens],
    assetsWithBalance: Object.values(assetsBalances),
    areAssetsWithBalanceLoading: isEthBalanceLoading || areErc20TokensLoading,
    refetchAssetsWithBalance: async () => {
      await refetchEthBalance()
      await handleERC20TokensBalance()
    },
  }
}
