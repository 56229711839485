import { Global } from '@emotion/react'
import React from 'react'

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Hoves';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/TT_Hoves_regular.woff') format('woff');
      }
      @font-face {
        font-family: 'Hoves';
        font-style: normal;
        font-weight: 500;
        src: url('/fonts/TT_Hoves_medium.woff') format('woff');
      }
      @font-face {
        font-family: 'Hoves';
        font-style: normal;
        font-weight: 600;
        src: url('/fonts/TT_Hoves_bold.woff') format('woff');
      }
      `}
  />
)

export default Fonts
