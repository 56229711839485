import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import NextApp from 'next/app'
import type { AppProps, AppContext, AppInitialProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import React from 'react'
import { cookieToInitialState, WagmiProvider } from 'wagmi'
import { ToastContainer } from 'components/Toast'
import { useApollo } from 'hooks/useApollo'
import { wagmiConfig } from 'lib/wagmi'
import Fonts from 'pages/_fonts'
import { AssetProvider } from 'providers/AssetProvider'
import { FeatureFlagsProvider } from 'providers/FeatureFlagsProvider'
import { ReactQueryClientProvider } from 'providers/ReactQueryClientProvider'
import { theme } from 'theme'
import type { BaseAppProps, NextPageWithLayout } from 'types/next'

type AppPropsWithLayout = AppProps<BaseAppProps> & {
  Component: NextPageWithLayout<BaseAppProps>
  cookieHeader?: string
}

const App = ({ Component, pageProps, cookieHeader }: AppPropsWithLayout) => {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <ToastContainer />
      <ApolloProvider client={apolloClient}>
        <WagmiProvider
          config={wagmiConfig}
          reconnectOnMount
          // *: https://wagmi.sh/react/guides/ssr
          {...(cookieHeader && { initialState: cookieToInitialState(wagmiConfig, cookieHeader) })}
        >
          <ReactQueryClientProvider>
            <FeatureFlagsProvider>
              <AssetProvider>{getLayout(<Component {...pageProps} />)}</AssetProvider>
            </FeatureFlagsProvider>
          </ReactQueryClientProvider>
        </WagmiProvider>
      </ApolloProvider>
    </ChakraProvider>
  )
}

// https://nextjs.org/docs/pages/building-your-application/routing/custom-app#getinitialprops-with-app
App.getInitialProps = async (
  context: AppContext,
): Promise<AppInitialProps & { cookieHeader?: string }> => {
  const ctx = await NextApp.getInitialProps(context)

  return { ...ctx, cookieHeader: context.ctx.req?.headers.cookie }
}

const AppWithI18n = appWithTranslation(App)

export default AppWithI18n
