/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * ZKR Bridge
 * Description
 * OpenAPI spec version: 1.0.11
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import { customAxiosInstance } from '../utils/axios';
import type { ErrorType } from '../utils/axios';
export type GetCurrentQuarantinedTransactions200 = {
  data?: QuarantinedTransactions;
  paginationContext?: PaginationContext;
};

export type GetRecentTransactions200 = {
  data: RecentTransactionsList;
  paginationContext: PaginationContext;
};

export type GetTransactions200 = {
  data: TransactionList;
  paginationContext: PaginationContext;
};

/**
 * Filter results by sender address hash.
 */
export type FilterByFromParamParameter = string;

/**
 * Filter results by address hash.
 */
export type FilterByAddressParamParameter = string;

export type FilterTxTypeParamParameterOneOfItem = typeof FilterTxTypeParamParameterOneOfItem[keyof typeof FilterTxTypeParamParameterOneOfItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterTxTypeParamParameterOneOfItem = {
  l1l2: 'l1l2',
  l2l1: 'l2l1',
} as const;

export type FilterTxTypeParamParameter = 'l1l2' | 'l2l1' | FilterTxTypeParamParameterOneOfItem[];

/**
 * Offset of results.
 */
export type OffsetParamParameter = number;

/**
 * Max number of results.
 */
export type LimitParamParameter = number;

export type GetQuarantinedTransactionByTxHashParams = {
/**
 * Max number of results.
 */
limit?: LimitParamParameter;
/**
 * Offset of results.
 */
offset?: OffsetParamParameter;
};

export type GetCurrentQuarantinedTransactionsParams = {
/**
 * Max number of results.
 */
limit?: LimitParamParameter;
/**
 * Offset of results.
 */
offset?: OffsetParamParameter;
/**
 * Filter results by sender address hash.
 */
from?: FilterByFromParamParameter;
};

export type GetRecentTransactionsParams = {
/**
 * Max number of results.
 */
limit?: LimitParamParameter;
/**
 * Offset of results.
 */
offset?: OffsetParamParameter;
/**
 * Filter results by type.
 */
txType?: FilterTxTypeParamParameter;
/**
 * Filter results by address hash.
 */
address?: FilterByAddressParamParameter;
};

export type GetTransactionsParams = {
/**
 * Max number of results.
 */
limit?: LimitParamParameter;
/**
 * Offset of results.
 */
offset?: OffsetParamParameter;
/**
 * Filter results by type.
 */
txType?: FilterTxTypeParamParameter;
/**
 * Filter results by address hash.
 */
address?: FilterByAddressParamParameter;
};

export type IsTransactionQuarantinedQuarantineQuarantineType = typeof IsTransactionQuarantinedQuarantineQuarantineType[keyof typeof IsTransactionQuarantinedQuarantineQuarantineType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IsTransactionQuarantinedQuarantineQuarantineType = {
  DEPOSIT: 'DEPOSIT',
  POOL: 'POOL',
} as const;

/**
 * @nullable
 */
export type IsTransactionQuarantinedQuarantine = {
  detector: string;
  expiresOn: number;
  from: string;
  isReleased: boolean;
  loss: number;
  nonce: number;
  quarantinedAt: number;
  quarantinedReason: string;
  quarantineType: IsTransactionQuarantinedQuarantineQuarantineType;
  releasedAt: number;
  releasedBy: string;
  releasedReason: string;
  txHash: string;
  value: number;
} | null;

export interface IsTransactionQuarantined {
  isQuarantined: boolean;
  /** @nullable */
  quarantine: IsTransactionQuarantinedQuarantine;
  txHash: string;
}

export type QuarantinedTransactionsItem = {
  detector?: string;
  expiresOn?: number;
  from?: string;
  isReleased?: boolean;
  loss?: number;
  nonce?: number;
  quarantinedAt?: number;
  quarantinedReason?: string;
  releasedAt?: number;
  releasedBy?: string;
  releasedReason?: string;
  txHash?: string;
  value?: number;
};

export type QuarantinedTransactions = QuarantinedTransactionsItem[];

export type RecentTransactionsList = RecentTransactionsListItem[];

export type RecentTransactionsListItemTxType = typeof RecentTransactionsListItemTxType[keyof typeof RecentTransactionsListItemTxType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecentTransactionsListItemTxType = {
  l1l2: 'l1l2',
  l2l1: 'l2l1',
} as const;

export type RecentTransactionsListItemTransfersItem = {
  decimals?: string;
  symbol?: string;
  wei?: string;
};

export type RecentTransactionsListItemStatus = typeof RecentTransactionsListItemStatus[keyof typeof RecentTransactionsListItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecentTransactionsListItemStatus = {
  pending: 'pending',
  successful: 'successful',
  failed: 'failed',
  replaced: 'replaced',
} as const;

export type RecentTransactionsListItemExitStatus = typeof RecentTransactionsListItemExitStatus[keyof typeof RecentTransactionsListItemExitStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecentTransactionsListItemExitStatus = {
  waiting: 'waiting',
  ready_to_prove: 'ready_to_prove',
  in_challenge_period: 'in_challenge_period',
  ready_to_relay: 'ready_to_relay',
  relayed: 'relayed',
} as const;

export type RecentTransactionsListItem = {
  exitStatus: RecentTransactionsListItemExitStatus;
  l1TxHash: string;
  l2TxHash: string;
  status: RecentTransactionsListItemStatus;
  timestamp: number;
  transfers: RecentTransactionsListItemTransfersItem[];
  txType: RecentTransactionsListItemTxType;
  value: string;
};

export type TransactionList = TransactionListItem[];

export type TransactionListItemTxType = typeof TransactionListItemTxType[keyof typeof TransactionListItemTxType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionListItemTxType = {
  l1l2: 'l1l2',
  l2l1: 'l2l1',
} as const;

export type TransactionListItemTransfersItem = {
  decimals?: string;
  symbol?: string;
  wei?: string;
};

export type TransactionListItemStatus = typeof TransactionListItemStatus[keyof typeof TransactionListItemStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionListItemStatus = {
  pending: 'pending',
  successful: 'successful',
  failed: 'failed',
  replaced: 'replaced',
} as const;

export type TransactionListItemExitStatus = typeof TransactionListItemExitStatus[keyof typeof TransactionListItemExitStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionListItemExitStatus = {
  waiting: 'waiting',
  ready_to_prove: 'ready_to_prove',
  in_challenge_period: 'in_challenge_period',
  ready_to_relay: 'ready_to_relay',
  relayed: 'relayed',
} as const;

export type TransactionListItem = {
  exitStatus: TransactionListItemExitStatus;
  l1TxHash: string;
  l2TxHash: string;
  status: TransactionListItemStatus;
  timestamp: number;
  transfers: TransactionListItemTransfersItem[];
  txType: TransactionListItemTxType;
  value: string;
};

export interface PaginationContext {
  limit: number;
  offset: number;
  totalElements: number;
}




type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getTransactions = (
    params?: GetTransactionsParams,
 options?: SecondParameter<typeof customAxiosInstance>,signal?: AbortSignal
) => {
      
      
      return customAxiosInstance<GetTransactions200>(
      {url: `/transactions`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTransactionsQueryKey = (params?: GetTransactionsParams,) => {
    return [`/transactions`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTransactionsQueryOptions = <TData = Awaited<ReturnType<typeof getTransactions>>, TError = ErrorType<unknown>>(params?: GetTransactionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>>, request?: SecondParameter<typeof customAxiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransactionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactions>>> = ({ signal }) => getTransactions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getTransactions>>>
export type GetTransactionsQueryError = ErrorType<unknown>

export const useGetTransactions = <TData = Awaited<ReturnType<typeof getTransactions>>, TError = ErrorType<unknown>>(
 params?: GetTransactionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactions>>, TError, TData>>, request?: SecondParameter<typeof customAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransactionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getRecentTransactions = (
    params?: GetRecentTransactionsParams,
 options?: SecondParameter<typeof customAxiosInstance>,signal?: AbortSignal
) => {
      
      
      return customAxiosInstance<GetRecentTransactions200>(
      {url: `/transactions/recent`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRecentTransactionsQueryKey = (params?: GetRecentTransactionsParams,) => {
    return [`/transactions/recent`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRecentTransactionsQueryOptions = <TData = Awaited<ReturnType<typeof getRecentTransactions>>, TError = ErrorType<unknown>>(params?: GetRecentTransactionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecentTransactions>>, TError, TData>>, request?: SecondParameter<typeof customAxiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRecentTransactionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecentTransactions>>> = ({ signal }) => getRecentTransactions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRecentTransactions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRecentTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getRecentTransactions>>>
export type GetRecentTransactionsQueryError = ErrorType<unknown>

export const useGetRecentTransactions = <TData = Awaited<ReturnType<typeof getRecentTransactions>>, TError = ErrorType<unknown>>(
 params?: GetRecentTransactionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecentTransactions>>, TError, TData>>, request?: SecondParameter<typeof customAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRecentTransactionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getCurrentQuarantinedTransactions = (
    params?: GetCurrentQuarantinedTransactionsParams,
 options?: SecondParameter<typeof customAxiosInstance>,signal?: AbortSignal
) => {
      
      
      return customAxiosInstance<GetCurrentQuarantinedTransactions200>(
      {url: `/quarantine`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCurrentQuarantinedTransactionsQueryKey = (params?: GetCurrentQuarantinedTransactionsParams,) => {
    return [`/quarantine`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCurrentQuarantinedTransactionsQueryOptions = <TData = Awaited<ReturnType<typeof getCurrentQuarantinedTransactions>>, TError = ErrorType<unknown>>(params?: GetCurrentQuarantinedTransactionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrentQuarantinedTransactions>>, TError, TData>>, request?: SecondParameter<typeof customAxiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentQuarantinedTransactionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentQuarantinedTransactions>>> = ({ signal }) => getCurrentQuarantinedTransactions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCurrentQuarantinedTransactions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCurrentQuarantinedTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentQuarantinedTransactions>>>
export type GetCurrentQuarantinedTransactionsQueryError = ErrorType<unknown>

export const useGetCurrentQuarantinedTransactions = <TData = Awaited<ReturnType<typeof getCurrentQuarantinedTransactions>>, TError = ErrorType<unknown>>(
 params?: GetCurrentQuarantinedTransactionsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrentQuarantinedTransactions>>, TError, TData>>, request?: SecondParameter<typeof customAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCurrentQuarantinedTransactionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getQuarantinedTransactionByTxHash = (
    txHash: string,
    params?: GetQuarantinedTransactionByTxHashParams,
 options?: SecondParameter<typeof customAxiosInstance>,signal?: AbortSignal
) => {
      
      
      return customAxiosInstance<IsTransactionQuarantined>(
      {url: `/quarantine/check/${txHash}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetQuarantinedTransactionByTxHashQueryKey = (txHash: string,
    params?: GetQuarantinedTransactionByTxHashParams,) => {
    return [`/quarantine/check/${txHash}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetQuarantinedTransactionByTxHashQueryOptions = <TData = Awaited<ReturnType<typeof getQuarantinedTransactionByTxHash>>, TError = ErrorType<unknown>>(txHash: string,
    params?: GetQuarantinedTransactionByTxHashParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getQuarantinedTransactionByTxHash>>, TError, TData>>, request?: SecondParameter<typeof customAxiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQuarantinedTransactionByTxHashQueryKey(txHash,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuarantinedTransactionByTxHash>>> = ({ signal }) => getQuarantinedTransactionByTxHash(txHash,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(txHash), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getQuarantinedTransactionByTxHash>>, TError, TData> & { queryKey: QueryKey }
}

export type GetQuarantinedTransactionByTxHashQueryResult = NonNullable<Awaited<ReturnType<typeof getQuarantinedTransactionByTxHash>>>
export type GetQuarantinedTransactionByTxHashQueryError = ErrorType<unknown>

export const useGetQuarantinedTransactionByTxHash = <TData = Awaited<ReturnType<typeof getQuarantinedTransactionByTxHash>>, TError = ErrorType<unknown>>(
 txHash: string,
    params?: GetQuarantinedTransactionByTxHashParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getQuarantinedTransactionByTxHash>>, TError, TData>>, request?: SecondParameter<typeof customAxiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetQuarantinedTransactionByTxHashQueryOptions(txHash,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




